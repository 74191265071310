import React from 'react';
import { css } from '@emotion/react';
import Image from '../../atoms/Image/Image';
import Select from 'react-select';

const Ubicaciones = ({
  estilo,
  titulo,
  urlGoogle,
  urlWaze,
  texto,
  ubicaciones,
}) => {
  const contentMapCss = css`
    width: 100%;
    padding-bottom: calc(100vw * (calc(127 / var(--width_base))));
    background-color: #444443;
    color: white;
    @media (max-width: 767px) {
      background-color: #444443;
      padding-bottom: initial;
      padding-left: calc(100vw * (calc(15 / var(--width_base))));
      padding-right: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const contentCss = css`
    @media (min-width: 768px) {
      padding-left: calc(100vw * (calc(150 / var(--width_base))));
      padding-right: calc(100vw * (calc(150 / var(--width_base))));
    }
  `;

  const headerMapCss = css`
    display: flex;
    justify-content: space-between;
    padding-top: calc(100vw * (calc(50 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(60 / var(--width_base))));

    @media (max-width: 767px) {
      flex-direction: column;
      padding-top: calc(100vw * (calc(40 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const titleCss = css`
    font-size: calc(100vw * (calc(32 / var(--width_base))));
    line-height: calc(100vw * (calc(39 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
    font-family: 'Montserrat-Bold', sans-serif;

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(20 / var(--width_base))));
      line-height: calc(100vw * (calc(24 / var(--width_base))));
    }
  `;

  const textCss = css`
    font-size: calc(100vw * (calc(18 / var(--width_base))));
    line-height: calc(100vw * (calc(21 / var(--width_base))));

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(17 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(15 / var(--width_base))));
    }
  `;

  const headerRightCss = css`
    display: flex;
    gap: calc(100vw * (calc(20 / var(--width_base))));
    align-items: center;
  `;

  const btnCss = css`
    border: 1px solid #93ba1f;
    color: white;
    font-size: calc(100vw * (calc(16 / var(--width_base))));
    line-height: calc(100vw * (calc(19 / var(--width_base))));
    padding: calc(100vw * (calc(10 / var(--width_base))))
      calc(100vw * (calc(20 / var(--width_base))));
    gap: calc(100vw * (calc(10 / var(--width_base))));
    align-items: center;
    display: flex;

    svg {
      width: calc(100vw * (calc(20 / var(--width_base))));
      height: calc(100vw * (calc(20 / var(--width_base))));
    }

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(17 / var(--width_base))));
      gap: calc(100vw * (calc(5 / var(--width_base))));
      padding: calc(100vw * (calc(10 / var(--width_base))))
        calc(100vw * (calc(9 / var(--width_base))));
    }
  `;

  const btn1Css = css`
    ${btnCss};
    background-color: #93ba1f;
  `;

  const btn2Css = css`
    ${btnCss};
  `;

  const ubicacionesCss = css`
    display: flex;
  `;

  const ubicacionesLeftCss = css`
    width: calc(100vw * (calc(484 / var(--width_base))));
    background-color: #5e5e5e;
    padding-top: calc(100vw * (calc(40 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(111 / var(--width_base))));
    padding-left: calc(100vw * (calc(49 / var(--width_base))));
    padding-right: calc(100vw * (calc(49 / var(--width_base))));
    flex-shrink: 0;
  `;

  const ubicacionesRightCss = css``;

  const ubicacionItemCss = css`
    display: flex;
    gap: calc(100vw * (calc(10 / var(--width_base))));
    padding-top: calc(100vw * (calc(30 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(30 / var(--width_base))));
    border-bottom: 1px solid white;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      color: #93ba1f;

      .img-no-hover {
        display: none;
      }

      path {
        fill: #93ba1f;
        stroke: #93ba1f;
      }
    }
    &:not(:hover) {
      .img-hover {
        display: none;
      }
    }
    &.active {
      color: #93ba1f;
    }

    path {
      fill: #93ba1f;
      stroke: #93ba1f;
    }
  `;

  const ubicacionItemImageCss = css`
    width: 100%;
    height: calc(100vw * (calc(781 / var(--width_base))));

    @media (max-width: 767px) {
      height: auto;
    }
  `;

  const ubicacionTextoCss = css`
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    line-height: calc(100vw * (calc(26 / var(--width_base))));
  `;

  const ubicacionIconoCss = css`
    width: calc(100vw * (calc(20 / var(--width_base))));
    height: calc(100vw * (calc(20 / var(--width_base))));
  `;

  const ubicacionIconoContainerCss = css`
    ${ubicacionIconoCss};
  `;

  const ubicacionIconArrowCss = css`
    width: calc(100vw * (calc(25 / var(--width_base))));
    height: calc(100vw * (calc(25 / var(--width_base))));
    @media (max-width: 767px) {
      width: calc(100vw * (calc(18 / var(--width_base))));
      height: calc(100vw * (calc(19 / var(--width_base))));
    }
  `;

  const ubicacionGroupTextCss = css`
    display: flex;
    align-items: center;
    gap: calc(100vw * (calc(10 / var(--width_base))));
    pointer-events: none;
  `;

  const ubicacionSelectCss = css`
    display: block;
    margin-top: calc(100vw * (calc(20 / var(--width_base))));
    background-color: transparent;
    color: white;
    max-width: calc(100vw * (calc(265 / var(--width_base))));
    > div > div:nth-of-type(1) > div:nth-of-type(1) > .select-group > svg {
      // display: none;
      // outline: 3px solid red;
    }

    @media (max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
    }
    :focus {
      color: white;
    }
  `;

  const handleClick = (i, e) => {
    const $this = e.currentTarget;
    const $ubicaciones = document.querySelectorAll('.ubicacion-item');
    $ubicaciones.forEach((ubicacion) => {
      ubicacion.classList.remove('active');
    });
    $this.classList.add('active');
    console.log('this', $this);
    const images = document.querySelectorAll('.ubicacion-image');
    images.forEach((image) => {
      const dataIndex = image.getAttribute('data-index');

      if (dataIndex == i) {
        image.classList.remove('hidden');
      } else {
        image.classList.add('hidden');
      }
    });
  };

  const handleSelect = ({ value: i }) => {
    const images = document.querySelectorAll('.ubicacion-image');
    images.forEach((image) => {
      const dataIndex = image.getAttribute('data-index');

      if (dataIndex == i) {
        image.classList.remove('hidden');
      } else {
        image.classList.add('hidden');
      }
    });
  };

  let selectOptions = [];
  if (ubicaciones) {
    selectOptions = ubicaciones.map((ubicacion, index) => ({
      value: index,
      label: ubicacion?.texto,
      icon: ubicacion?.iconoBoton?.sourceUrl,
    }));
  }

  return (
    <section css={contentMapCss}>
      <div css={contentCss}>
        <div css={headerMapCss}>
          <div>
            {titulo && (
              <h2 css={titleCss} dangerouslySetInnerHTML={{ __html: titulo }} />
            )}
            {texto && (
              <div css={textCss} dangerouslySetInnerHTML={{ __html: texto }} />
            )}
          </div>
          <div css={headerRightCss}>
            <a css={btn1Css} target="_blank" href="">
              <svg
                width="16"
                height="21"
                viewBox="0 0 16 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_166_488)">
                  <path
                    d="M4.41162 15.3155C5.07496 16.0527 5.67873 16.8292 6.21856 17.6393C6.67943 18.4013 6.87156 18.918 7.20837 19.8361C7.41493 20.3433 7.60162 20.4947 8.003 20.4947C8.44037 20.4947 8.63881 20.2372 8.79212 19.8389C9.1105 18.9718 9.36031 18.3101 9.75443 17.6849C10.5278 16.4778 11.4888 15.4052 12.4331 14.3743C12.6887 14.0825 14.3414 12.3827 15.0855 11.0415C15.0855 11.0415 16 9.56823 16 7.51062C16 5.58594 15.0981 4.25106 15.0981 4.25106L12.5016 4.85738L10.9247 8.47869L10.5346 8.97804L10.4566 9.0685L10.3528 9.18174L10.1707 9.36261L9.91043 9.58948L8.50625 10.587L4.99562 12.3544L4.41162 15.3155Z"
                    fill="#34A853"
                  />
                  <path
                    d="M0.78833 10.7938C1.64514 12.5003 3.29739 14.0003 4.41527 15.3167L10.3528 9.18409C10.3528 9.18409 9.51633 10.138 7.99896 10.138C6.30877 10.138 4.94333 8.96114 4.94333 7.47722C4.94333 6.45962 5.64546 5.76054 5.64546 5.76054L1.61489 6.70223L0.78833 10.7938Z"
                    fill="#FBBC04"
                  />
                  <path
                    d="M10.4191 0.815369C12.3911 1.36976 14.079 2.53362 15.1 4.24986L10.3546 9.18055C10.3546 9.18055 11.0568 8.46883 11.0568 7.45755C11.0568 5.93907 9.59033 4.80469 8.00614 4.80469C6.50808 4.80469 5.64771 5.757 5.64771 5.757V2.65074L10.4191 0.815369Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M1.87598 2.99401C3.05379 1.76529 5.12629 0.5 7.98354 0.5C9.36979 0.5 10.4142 0.81733 10.4142 0.81733L5.64316 5.75973H2.26285L1.87598 2.99401Z"
                    fill="#1A73E8"
                  />
                  <path
                    d="M0.78825 10.7938C0.78825 10.7938 0 9.4479 0 7.49804C0 5.65515 0.821625 4.04425 1.87594 2.994L5.64581 5.76016L0.78825 10.7938Z"
                    fill="#EA4335"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_488">
                    <rect
                      width="16"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Ver en Google Maps
            </a>
            <a css={btn2Css} href="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="48px"
                height="48px"
              >
                <path
                  fill="#37474f"
                  d="M27,38C9.1,38,5.2,33.2,3.6,31.1c-0.4-0.4-0.6-1-0.6-1.6C3,28.1,4.1,27,5.5,27C6.4,27,9,27,9,22.1 v-0.6C9,12.4,17.1,5,27,5s18,7.4,18,16.5S36.9,38,27,38z"
                />
                <path
                  fill="#eceff1"
                  d="M27,36c8.8,0,16-6.5,16-14.5S35.8,7,27,7s-16,6.5-16,14.5v0.6c0,6.2-3.8,6.9-5.5,6.9 C5.2,29,5,29.2,5,29.5c0,0.1,0,0.2,0.1,0.3C6.6,31.7,10,36,27,36z"
                />
                <path
                  fill="#37474f"
                  d="M32 16A2 2 0 1 0 32 20 2 2 0 1 0 32 16zM22 16A2 2 0 1 0 22 20 2 2 0 1 0 22 16zM27 29c-4.8 0-6.7-3.5-7-5.3-.1-.5.3-1.1.8-1.2.5-.1 1.1.3 1.2.8 0 .1.7 3.7 5 3.7 4.3 0 5-3.5 5-3.7.1-.5.6-.9 1.2-.8.5.1.9.6.8 1.1C33.7 25.5 31.8 29 27 29zM16.5 34A4.5 4.5 0 1 0 16.5 43 4.5 4.5 0 1 0 16.5 34z"
                />
                <path
                  fill="#607d8b"
                  d="M16.5 37A1.5 1.5 0 1 0 16.5 40A1.5 1.5 0 1 0 16.5 37Z"
                />
                <path
                  fill="#37474f"
                  d="M32.5 34A4.5 4.5 0 1 0 32.5 43A4.5 4.5 0 1 0 32.5 34Z"
                />
                <path
                  fill="#607d8b"
                  d="M32.5 37A1.5 1.5 0 1 0 32.5 40A1.5 1.5 0 1 0 32.5 37Z"
                />
              </svg>
              Ir con Waze
            </a>
          </div>
          <div className="only-mov">
            <Select
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: 'transparent',
                  border: '1px solid #93ba1f',
                  color: 'white',
                  borderRadius: '0',
                  maxWidth: 'calc(100vw * (calc(265 / var(--width_base))))',
                  ':hover': {
                    border: '1px solid #93ba1f !important',
                    boxShadow: '0 0 0 1px #93ba1f !important',
                  },
                }),
                menu: (styles) => ({
                  ...styles,
                  backgroundColor: '#444443',
                  padding:
                    'calc(100vw * (calc(20 / var(--width_base)))) calc(100vw * (calc(10 / var(--width_base))))',
                }),
                option: (styles) => ({
                  ...styles,
                  backgroundColor: '#444443',
                  color: 'white',
                  fontSize: 'calc(100vw * (calc(16 / var(--width_base))))',
                  lineHeight: 'calc(100vw * (calc(16 / var(--width_base))))',
                  padding: 'calc(100vw * (calc(20 / var(--width_base)))) 0',
                  borderBottom: '1px solid white',
                }),
              }}
              css={ubicacionSelectCss}
              placeholder="Selecciona"
              isClearable={false}
              isSearchable={true}
              options={selectOptions}
              onChange={handleSelect}
              getOptionLabel={(props) => {
                const { label, icon } = props;
                return (
                  <div className="select-group">
                    <div className="select-group__left">
                      <img className="select-icon" src={icon} />
                      <span>{label}</span>
                    </div>
                    {/*
                      <svg
                      css={ubicacionIconArrowCss}
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.32198 4.14531C8.18568 4.28483 8.10938 4.47214 8.10938 4.66718C8.10938 4.86223 8.18568 5.04953 8.32198 5.18906L15.936 13L8.32198 20.8094C8.18568 20.9489 8.10938 21.1362 8.10938 21.3312C8.10937 21.5263 8.18568 21.7136 8.32198 21.8531C8.38822 21.9212 8.46742 21.9752 8.55491 22.0122C8.6424 22.0491 8.73639 22.0681 8.83135 22.0681C8.92631 22.0681 9.02031 22.0491 9.10779 22.0122C9.19528 21.9752 9.27448 21.9212 9.34073 21.8531L17.4376 13.5453C17.5798 13.3994 17.6594 13.2037 17.6594 13C17.6594 12.7963 17.5798 12.6006 17.4376 12.4547L9.34073 4.14687C9.27448 4.07883 9.19528 4.02476 9.10779 3.98783C9.02031 3.95091 8.92631 3.93188 8.83135 3.93188C8.73639 3.93188 8.6424 3.95091 8.55491 3.98783C8.46742 4.02476 8.38822 4.07883 8.32198 4.14687V4.14531Z"
                        fill="white"
                      />
                    </svg>
                      */}
                  </div>
                );
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
        <div css={ubicacionesCss}>
          <div css={ubicacionesLeftCss} className="only-desk">
            {ubicaciones &&
              ubicaciones.map((ubicacion, index) => (
                <div
                  key={index}
                  css={ubicacionItemCss}
                  onClick={(e) => handleClick(index, e)}
                  className="ubicacion-item"
                >
                  <div css={ubicacionGroupTextCss}>
                    <Image
                      classEle="img-no-hover"
                      objectFit="contain"
                      styleContentCss={ubicacionIconoContainerCss}
                      styleCss={ubicacionIconoCss}
                      imagen={ubicacion?.iconoBoton}
                    />
                    <Image
                      classEle="img-hover"
                      objectFit="contain"
                      styleContentCss={ubicacionIconoContainerCss}
                      styleCss={ubicacionIconoCss}
                      imagen={ubicacion?.iconoBoton}
                    />
                    <div css={ubicacionTextoCss}>{ubicacion.texto}</div>
                  </div>
                  <svg
                    css={ubicacionIconArrowCss}
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.32198 4.14531C8.18568 4.28483 8.10938 4.47214 8.10938 4.66718C8.10938 4.86223 8.18568 5.04953 8.32198 5.18906L15.936 13L8.32198 20.8094C8.18568 20.9489 8.10938 21.1362 8.10938 21.3312C8.10937 21.5263 8.18568 21.7136 8.32198 21.8531C8.38822 21.9212 8.46742 21.9752 8.55491 22.0122C8.6424 22.0491 8.73639 22.0681 8.83135 22.0681C8.92631 22.0681 9.02031 22.0491 9.10779 22.0122C9.19528 21.9752 9.27448 21.9212 9.34073 21.8531L17.4376 13.5453C17.5798 13.3994 17.6594 13.2037 17.6594 13C17.6594 12.7963 17.5798 12.6006 17.4376 12.4547L9.34073 4.14687C9.27448 4.07883 9.19528 4.02476 9.10779 3.98783C9.02031 3.95091 8.92631 3.93188 8.83135 3.93188C8.73639 3.93188 8.6424 3.95091 8.55491 3.98783C8.46742 4.02476 8.38822 4.07883 8.32198 4.14687V4.14531Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ))}
          </div>
          <div className="w-full" css={ubicacionesRightCss}>
            {ubicaciones &&
              ubicaciones.map((ubicacion, index) => {
                const principal = ubicacion?.principal;
                return (
                  <div
                    key={index}
                    data-index={index}
                    className={`ubicacion-image ubicacion-image-${index} ${
                      principal ?? 'hidden'
                    }`}
                  >
                    <Image
                      styleCss={ubicacionItemImageCss}
                      imagen={ubicacion?.imagen}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ubicaciones;
